# Array methods

Object.defineProperty Array, 'map', value: (callbackFn, arrays...) ->
  length = arrays.min(array => array.length); results = []
  results[index] = callbackFn.apply null, arrays.map(array => array[index]) for index in [0 ... length]
  results

Object.defineProperty Array.prototype, 'compact', value: () ->
  @filter (item) => item?

Object.defineProperty Array.prototype, 'shuffle', value: () ->
  for currentIndex in [@length - 1 ... 0] by -1
    randomIndex = Math.floor(Math.random() * (currentIndex + 1))
    [@[currentIndex], @[randomIndex]] = [@[randomIndex], @[currentIndex]]
  @

Object.defineProperty Array.prototype, 'count', value: (callbackFn = (() => true), initialValue = 0) ->
  @sum ((element) => if callbackFn(element) then 1 else 0), initialValue

Object.defineProperty Array.prototype, 'sum', value: (callbackFn = ((item) => item), initialValue = 0) ->
  @reduce ((sum, element) => sum + callbackFn(element)), initialValue

Object.defineProperty Array.prototype, 'min', value: (callbackFn = ((item) => item), initialValue = Infinity) ->
  @reduce ((min, element) => Math.min(min, callbackFn(element))), initialValue

Object.defineProperty Array.prototype, 'max', value: (callbackFn = ((item) => item), initialValue = -Infinity) ->
  @reduce ((max, element, index, array) => Math.max(max, callbackFn element, array)), initialValue

Object.defineProperty Array.prototype, 'group', value: (callbackFn, thisArg) ->
  @reduce ((result, item, index, array) =>
    key = callbackFn.call(thisArg, item, index, array)
    if key of result then result[key].push(item) else result[key] = [item]
    result), {}

Object.defineProperty Array.prototype, 'groupToMap', value: (callbackFn, thisArg) ->
  @reduce(((result, item, index, array) =>
    key = callbackFn.call(thisArg, item, index, array)
    if result.has(key) then result.get(key).push(item) else result.set(key, [item])
    result), new Map())

Object.defineProperty Array.prototype, 'transpose', value: (callbackFn, thisArg) ->
  keys = []
  @reduce ((result, array) =>
    array.forEach((item) =>
      key = callbackFn.call(thisArg, item)
      index = keys.indexOf(key)
      if index < 0 then keys.push(key)
      if index >= 0 then result[index].push(item) else result[result.length] = [item])
    result), []

Object.defineProperty Array.prototype, 'reorder', value: (callbackFn, thisArg) ->
  @reduce ((result, item) =>
    result[callbackFn.call(thisArg, item)] = item
    result), []
